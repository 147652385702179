.institutionCard {
    background-color: #ffe700;
}

.institutionCardHeader {
    background-color: #f4cd00;
    font-weight: bold;
}

table.institutionEntryList {
    width: 100%;
    border-collapse: collapse;
}

table.institutionEntryList tbody tr:nth-child(odd) {
    background-color: #fff400;
}

table.institutionEntryList th {
    text-align: left;
}

table.institutionEntryList th, table.institutionEntryList td {
    padding: 0.25em 1em;
    vertical-align: top;
}
