.typeValueCard {
    background-color: #f7eddb;
}

.typeValueCardHeader {
    background-color: #f7d5af;
    font-weight: bold;
}

table.typeValueList {
    width: 100%;
    border-collapse: collapse;
}

table.typeValueList tbody tr:nth-child(odd) {
    background-color: #fff4db;
}

table.typeValueList th {
    text-align: left;
}

table.typeValueList th, table.typeValueList td {
    padding: 0.25em 1em;
    vertical-align: top;
}
