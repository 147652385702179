.groupCard {
    background-color: #f7eddb;
}

.groupCardHeader {
    background-color: #f7d5af;
    font-weight: bold;
}

table.groupEntryList {
    width: 100%;
    border-collapse: collapse;
}

table.groupEntryList tbody tr:nth-child(odd) {
    background-color: #fff4db;
}

table.groupEntryList th {
    text-align: left;
}

table.groupEntryList th, table.groupEntryList td {
    padding: 0.25em 1em;
    vertical-align: top;
}
