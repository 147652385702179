.userCard {
    background-color: #ffe7ff;
}

.userCardHeader {
    background-color: #f4cdf4;
    font-weight: bold;
}

table.userEntryList {
    width: 100%;
    border-collapse: collapse;
}

table.userEntryList tbody tr:nth-child(odd) {
    background-color: #fff4ff;
}

table.userEntryList th {
    text-align: left;
}

table.userEntryList th, table.userEntryList td {
    padding: 0.25em 1em;
    vertical-align: top;
}
