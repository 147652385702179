.stringListCard {
    background-color: #ffe7ff;
}

.stringListCardHeader {
    background-color: #f4cdf4;
    font-weight: bold;
}

table.stringList {
    width: 100%;
    border-collapse: collapse;
}

table.stringList tbody tr:nth-child(odd) {
    background-color: #fff4ff;
}

table.stringList th {
    text-align: left;
}

table.stringList th, table.stringList td {
    padding: 0.25em 1em;
    vertical-align: top;
}
