.days button {
    width: 30px;
    height: 30px;
    font-weight: bold;
    border-radius: 50%;
    border: 2px solid #2b78e4;
}

.checked {
    color: white;
    background: #2b78e4;
    text-decoration: underline;
    text-decoration-thickness: 20%;
}

.unchecked {
    color: #2b78e4;
    background: white;
}
