.searchControl {
  margin-top: 8px;
  display: flex;

  &.marginBottom0 {
    margin-bottom: 0;
  }

  &.marginTop0 {
    margin-top: 0;
  }
}
